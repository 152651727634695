<template>
  <div>Template</div>
</template>

<script>
export default {
  name: 'LawyerTemplate',
  layout: 'lawyerDashboard',
}
</script>
